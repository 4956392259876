<script setup lang="ts">
import type { Nullable } from '@glow/shared/utils/types';

export type SearchResultsPartnerPreviewData = {
  id: number;
  title: string;
  avatarUrl?: Nullable<string>;
  rating: number;
};

const props = defineProps<{
  partner: SearchResultsPartnerPreviewData;
}>();
</script>

<template>
  <div class="partner-item text-black-secondary">
    <AppAvatar
      size="sm"
      :src="props.partner.avatarUrl"
      :name="props.partner.title"
    />
    <div>
      <div>{{ props.partner.title }}</div>
      <AppRating :model-value="props.partner.rating" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.partner-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--app-spacing-3);
}
</style>
