<script setup lang="ts">
import { SearchIcon, LeftArrowIcon } from '@glow/shared/components/icons';
import { SearchResults } from '@/features/partners';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const searchQuery = ref('');
</script>

<template>
  <div class="screen d-flex flex-column gap-3">
    <div class="d-flex align-items-center gap-3">
      <AppButton
        variant="link"
        :icon-start="LeftArrowIcon"
        icon-start-color="secondary"
        @click="emit('close')"
      />
      <AppInput
        v-model="searchQuery"
        fluid
        autofocus
        placeholder="Поиск"
        static-placeholder
      >
        <template #prepend>
          <AppIcon :icon="SearchIcon" />
        </template>
      </AppInput>
    </div>

    <SearchResults :search-query="searchQuery" />
  </div>
</template>

<style scoped lang="scss">
.screen {
  background: var(--app-color-white-primary);
}
</style>
