<script setup lang="ts" generic="T extends SearchResultsCategoryItem">
import type { LinkWithTitle } from '@glow/shared/utils/types';
import { useSearchHistory } from '../store/searchHistory';

export type SearchResultsCategoryItem = {
  id: number;
} & LinkWithTitle;

const props = withDefaults(
  defineProps<{
    title: string;
    items?: T[];
  }>(),
  { items: () => [] }
);

const searchHistoryStore = useSearchHistory();
function onItemClick(item: T) {
  searchHistoryStore.addItem(item);
  navigateTo(item.link);
}
</script>

<template>
  <div v-if="props.items.length" class="category">
    <div class="text-black-secondary">{{ props.title }}</div>
    <div class="category__items">
      <NuxtLink
        v-for="item in props.items"
        :key="item.id"
        :to="item.link"
        class="item"
        @click.prevent="onItemClick(item)"
      >
        <slot v-bind="item" name="item">
          <div class="item__name">
            {{ item.title }}
          </div>
        </slot>
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.category {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-3);

  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin-bottom: 12px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: var(--app-spacing-3);
  }
}

.item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 17px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(var(--app-color-black), 0.1);
  }

  &__name {
    color: var(--app-color-black);
  }

  &__info {
    color: var(--app-color-black-secondary);
  }
}
</style>
