<script setup lang="ts">
import { useSearchHistory } from '../store/searchHistory';
import { HistoryIcon } from '@glow/shared/components/icons';

const searchHistoryStore = useSearchHistory();
</script>

<template>
  <div v-if="searchHistoryStore.items.length" class="items">
    <AppButton
      v-for="item in searchHistoryStore.items"
      :key="item.link"
      :to="item.link"
      variant="link"
      color="dark"
      text-align="start"
      :icon-start="HistoryIcon"
      icon-start-color="dark"
    >
      {{ item.title }}
    </AppButton>
  </div>
</template>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-2);
}
</style>
