import type { LinkWithTitle } from '@glow/shared/utils/types';
import { useLocalStorage } from '@vueuse/core';
import { defineStore, skipHydrate } from 'pinia';

const SEARCH_HISTORY_STORAGE_KEY = 'search_history';
const MAX_SEARCH_HISTORY_ITEMS = 3;

export const useSearchHistory = defineStore('searchHistory', () => {
  const items = useLocalStorage<LinkWithTitle[]>(
    SEARCH_HISTORY_STORAGE_KEY,
    []
  );

  const addItem = (item: LinkWithTitle) => {
    const index = items.value.findIndex((i) => i.title === item.title);
    // If item's already in the history, bubble it up to the top
    if (index !== -1)
      [items.value[0], items.value[index]] = [
        items.value[index],
        items.value[0],
      ];
    else items.value.unshift(item);
    if (items.value.length > MAX_SEARCH_HISTORY_ITEMS)
      items.value.splice(
        MAX_SEARCH_HISTORY_ITEMS - 1,
        items.value.length - MAX_SEARCH_HISTORY_ITEMS
      );
  };
  return { items: readonly(skipHydrate(items)), addItem };
});
