<script setup lang="ts">
import { refDebounced } from '@vueuse/shared';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';
import SearchResultsCategory from './SearchResultsCategory.vue';
import SearchResultsRecentItems from './SearchResultsRecentItems.vue';
import SearchResultsPartnerPreview from './SearchResultsPartnerPreview.vue';
import { DEBOUNCE_INTERVAL_MS } from '@glow/shared/lib/constants';

const props = withDefaults(
  defineProps<{
    searchQuery?: string;
  }>(),
  { searchQuery: '' }
);

const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;
const searchQueryDebounced = refDebounced(
  toRef(props, 'searchQuery'),
  DEBOUNCE_INTERVAL_MS
);
const { data: queryData, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query SearchResults_PartnerSearchQuery($query: String!) {
      partnerSearch(query: $query) {
        id
        masters {
          id
          fullName
          slug
          rating
          avatarUrl
        }
        companies {
          id
          name
          slug
          rating
          logoUrl
        }
        specializations {
          id
          name
          slug
        }
        services {
          id
          name
          slug
          specialization {
            slug
          }
        }
      }
    }
  `),
  variables: toRef(() => ({
    query: searchQueryDebounced.value,
  })),
  pause: toRef(() => searchQueryDebounced.value.length < 3),
});
const searchResponse = toRef(() => queryData.value?.partnerSearch);
const isResponseEmpty = computed(() => {
  if (!searchResponse.value) return true;
  const { masters, companies, services, specializations } =
    searchResponse.value;
  return [masters, companies, services, specializations].every(
    (item) => item.length === 0
  );
});

const specializationItems = computed(() =>
  searchResponse.value?.specializations.map((specialization) => ({
    id: specialization.id,
    title: specialization.name,
    link: getUrl('services', {
      specialization: specialization.slug,
    })!,
  }))
);
const serviceItems = computed(() =>
  searchResponse.value?.services.map((service) => ({
    id: service.id,
    title: service.name,
    link: getUrl('partnerCatalog', {
      type: 'master',
      city: 'moscow',
      specialization: service.specialization.slug,
      service: service.slug,
    })!,
  }))
);
const masterItems = computed(() =>
  searchResponse.value?.masters.map((master) => ({
    ...master,
    title: master.fullName,
    link: getUrl('partnerDetails', {
      type: 'master',
      city: 'moscow',
      partner: master.slug,
    })!,
  }))
);
const companyItems = computed(() =>
  searchResponse.value?.companies.map((company) => ({
    ...company,
    title: company.name,
    link: getUrl('partnerDetails', {
      type: 'company',
      city: 'moscow',
      partner: company.slug,
    })!,
  }))
);
</script>

<template>
  <div class="search-results">
    <SearchResultsRecentItems />
    <div v-if="props.searchQuery.length < 3" class="text-center text-secondary">
      Начните искать салон красоты, мастера, специальность или услугу
    </div>
    <AppLoader v-else-if="fetching" />
    <div
      v-else-if="isResponseEmpty"
      class="d-flex flex-column align-items-center gap-2"
    >
      <NuxtImg
        width="150"
        height="150"
        :src="`${staticUrl}/images/icons/appointment.png`"
      />
      <div class="text-center text-black-secondary">
        Увы, по вашему запросу ничего не найдено.
      </div>
    </div>
    <template v-else>
      <SearchResultsCategory
        title="Специальности"
        :items="specializationItems"
      />
      <SearchResultsCategory title="Услуги" :items="serviceItems" />
      <SearchResultsCategory title="Мастера" :items="masterItems">
        <template #item="master">
          <SearchResultsPartnerPreview :partner="master" />
        </template>
      </SearchResultsCategory>
      <SearchResultsCategory title="Компании" :items="companyItems">
        <template #item="company">
          <SearchResultsPartnerPreview :partner="company" />
        </template>
      </SearchResultsCategory>
    </template>
  </div>
</template>

<style scoped lang="scss">
.search-results {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
